.wcm-pgraph-text-teaser {
  $this: &;
  &--centered {
    text-align: center;
  }
  // Inset Variation
  &--inset {
    background: white;
    margin-bottom: 30px;
    > a {
      display: block;
      border: none;
      &:hover {
        text-decoration: none;
        border: none;
      }
      &:after {
        display: none !important;
        content: '' !important;
      }
    }
    #{$this}__text {
      border: 30px solid #f7f7f7;
      padding: 40px;
      &__headline {
        color: $wcm-red;
        font-family: $wcm-bold;
        font-size: 20px;
        @include breakpoint($sm) {
          font-size: 24px;
        }
        a {
          color: $wcm-red;
          text-decoration: underline;
          border: none;
          @include breakpoint($md) {
            text-decoration: none;
          }
          &:after {
            content: '\e80d';
            color: $wcm-bright-orange;
            padding-left: 10px;
            font-size: 60%;
            vertical-align: middle;
            @include fontello();
            text-decoration: none;
            line-height: 1.5;
          }
          &:hover {
            border: none;
            @include breakpoint($md) {
              text-decoration: underline;
            }
          }
        }
      }
      &__description {
        color: $wcm-dark-gray;
      }
    }
  }
  &--inset#{$this}--over {
    #{$this}__text {
      @include breakpoint($md) {
        border-color: darken(#f7f7f7, 2%);  
      }
    }
  }
}
